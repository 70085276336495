import { RouteLocationNormalized, onBeforeRouteLeave } from 'vue-router'

import { useEventListener } from '@vueuse/core'

import { RefOrGetter } from '../../types'
import { useUser } from '../useUser'

export const useInternalNavigationGuard = (
  shouldGuard:
  | RefOrGetter<boolean>
  | ((to: RouteLocationNormalized, from: RouteLocationNormalized) => boolean),
  // eslint-disable-next-line no-alert
  onLeave: () => boolean = () => window.confirm('Are you sure? All unsaved changes will be lost.'),
) => {
  const { isLoggedIn } = useUser()

  let leavingViaSingleSpa = false

  useEventListener(window, 'single-spa:before-routing-event', () => {
    leavingViaSingleSpa = true
  })

  onBeforeRouteLeave((to, from) => {
    if (process.env.COMMAND === 'serve') {
      return true
    }

    const shouldGuardResult = !leavingViaSingleSpa
      && (typeof shouldGuard === 'function' ? shouldGuard(to, from) : shouldGuard.value)
      && isLoggedIn.value

    leavingViaSingleSpa = false

    return shouldGuardResult ? onLeave() : true
  })
}

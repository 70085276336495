import { RefOrGetter } from '../../types'
import {
  useExternalNavigationGuard,
  useInternalNavigationGuard,
  useSingleSpaNavigationGuard,
} from '../useNavigationGuard'

export const useNavigationGuard = (shouldGuard: RefOrGetter<boolean>) => {
  useExternalNavigationGuard(shouldGuard)
  useInternalNavigationGuard(shouldGuard)
  useSingleSpaNavigationGuard(shouldGuard)
}

import { onBeforeRouteLeave } from 'vue-router'
import { useEventListener } from '@vueuse/core'
import { SingleSpaCustomEventDetail } from 'single-spa'
import { toValue } from 'vue'

import { useUser } from '../useUser'
import { RefOrGetter } from '../../types'

export const useSingleSpaNavigationGuard = (
  shouldGuard: RefOrGetter<boolean>,
  // eslint-disable-next-line no-alert
  onLeave: () => boolean = () => window.confirm('Are you sure? All unsaved changes will be lost.'),
) => {
  const { isLoggedIn } = useUser()

  let leavingViaVueRouter = false

  onBeforeRouteLeave(() => {
    leavingViaVueRouter = true
  })

  useEventListener(
    window,
    'single-spa:before-routing-event',
    ({ detail: { cancelNavigation } }: CustomEvent<SingleSpaCustomEventDetail>) => {
      if (process.env.COMMAND === 'serve') {
        return
      }

      if (!leavingViaVueRouter && toValue(shouldGuard) && isLoggedIn.value && !onLeave()) {
        cancelNavigation?.()
      }

      leavingViaVueRouter = false
    },
  )
}
